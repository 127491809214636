// Poppins
@font-face {
  font-family: PoppinsBlack;
  src: url(../fonts/Poppins-Black.ttf);
}
@font-face {
  font-family: PoppinsBlackItalic;
  src: url(../fonts/Poppins-BlackItalic.ttf);
}
@font-face {
  font-family: PoppinsBold;
  src: url(../fonts/Poppins-Bold.ttf);
}
@font-face {
  font-family: PoppinsLight;
  src: url(../fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: PoppinsMedium;
  src: url(../fonts/Poppins-Medium.ttf);
}
@font-face {
  font-family: PoppinsSemiBold;
  src: url(../fonts/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: PoppinsRegular;
  src: url(../fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: PoppinsThin;
  src: url(../fonts/Poppins-Thin.ttf);
}
